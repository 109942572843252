import React from 'react';
import { Seo } from '../components/Seo';
import { SubpageLayout } from '../components/SubpageLayout';

const TermsPage = () => (
  <SubpageLayout
    title="Regulamin"
  >
    <Seo
      title="Regulamin"
    />
    <div className={'container terms-of-use'}>
      <div className={'content'}>
        <div className="content">
          <p style={{marginTop: 0}}>
            Niniejszy regulamin stanowi regulamin świadczenia usług elektronicznych w rozumieniu Ustawy z dnia
            18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz.U. 2017, poz. 1217 ze zm.) i określa
            zasady świadczenia usług za pośrednictwem Witryny lub Serwisu oraz innych domen, wskazujących na
            poszczególne części Serwisu, jak rόwnież Aplikacji Mobilnej.</p>
          <p>Załącznikiem stanowiącym integralną część Regulaminu jest umowa powierzenia przetwarzania danych
            osobowych. Poprzez akceptację Regulaminu akceptują Państwo również postanowienia umowy powierzenia
            przetwarzania danych osobowych i tym samym zawierają ją Państwo z Nami.</p>
          <p className="text-center m-10"><strong>§1</strong></p>
          <p>Definicje</p>
          <p>Użyte w Regulaminie pojęcia oznaczają:</p>
          <ol>
            <li>Operator Serwisu – Fakturuj sp. z o. o. z siedzibą w Gdańsku, ul. Jana z Kolna 11, 80-864, NIP
              5833327928, REGON 381729562,
            </li>
            <li>Serwis &#8211; serwis internetowy dostępny w Sieci Internet pod adresem głównym
              https://fakturuj.to/, umożliwiający dostęp do Usług;
            </li>
            <li>Aplikacja Mobilna – wersja Serwisu udostępniana przez Operatora Serwisu jako Aplikacja Mobilna w
              Google Play oraz Apple AppStore pod nazwą Fakturuj;
            </li>
            <li>Usługa &#8211; usługa świadczona w ramach Serwisu;</li>
            <li>Raporty Księgowe – raporty i dokumenty oferowane przez Serwis: Ewidencja Sprzedaży VAT,
              Ewidencja Zakupów VAT, Jednolite Pliki Kontrolne, raporty i dokumenty inwentaryzacyjne oraz
              raporty dostępne w ramach zawartej w Cenniku funkcji „Księgowość Online”;
            </li>
            <li>Użytkownik – osoba fizyczna, która posiada zdolność do czynności prawnych, osoba prawna lub
              jednostka organizacyjna niebędąca osobą prawną, której odrębne przepisy przyznają zdolność prawną,
              odwiedzająca Witrynę lub składająca zamówienie określające warunki Umowy, na podstawie której
              Strony będą zobowiązane do świadczeń wynikających z niniejszej Umowy. W przypadku zawarcia umowy
              przez osobę fizyczną o ograniczonej zdolności do czynności prawnych konieczne jest potwierdzenie
              Umowy na piśmie przez przedstawiciela ustawowego tej osoby.
            </li>
            <li>Umowa &#8211; umowa o świadczeniu Usług w sposób ciągły, rozliczana cyklicznie w odnawialnych
              Okresach Rozliczeniowych;
            </li>
            <li>Konto – oznaczony indywidualną nazwą (loginem), tj. adresem e-mail i hasłem ustalanym przez
              Użytkownika, zbiór zasobów w systemie teleinformatycznym Operatora Serwisu, w którym gromadzone są
              dane Użytkownika;
            </li>
            <li>Abonament &#8211; opłata uiszczana przez Użytkownika za świadczenie przez Operatora Serwisu
              Usług w danym Okresie Rozliczeniowym;
            </li>
            <li>Pakiet Abonamentowy &#8211; pakiet dostępnych funkcji w ramach świadczonej przez Operatora
              Serwisu, wybranej przez Użytkownika Usługi;
            </li>
            <li>Okres Testowy-45 dni, nieodpłatny okres, przysługujący Użytkownikowi w celu zapoznania się z
              funkcjonalnością Serwisu, liczony od dnia zarejestrowania się i założenia konta w Serwisie;
            </li>
            <li>Okres Rozliczeniowy- okres , za który dokonywane są rozliczenia zobowiązań Użytkownika wobec
              Operatora Serwisu z tytułu świadczonych Usług w trybie abonamentowym, rozpoczynający się po
              upływie Okresu Testowego (aktywowaniu Pakietu Abonamentowego) i kończący się w kolejnym miesiącu w
              dniu odpowiadającym aktywowaniu Pakietu Abonamentowego. Jeżeli w danym miesiącu nie ma dnia
              odpowiadającego dniu aktywowaniu Pakietu Abonamentowego, rozliczenia dokonywane są w ostatnim dniu
              kolejnego miesiąca
            </li>
            <li>Cennik- aktualnie obowiązujący Cennik dostępny pod adresem internetowym
              https://fakturuj.to/cennik, zawierający listę dostępnych, pozostających w ofercie Serwisu Usług
              oraz ich ceny;
            </li>
            <li>Metody płatności – karta płatnicza; przelew online, bezpośredni przelew do banku. Podmiotem
              świadczącym usługi płatnicze na rzecz Użytkowników jest PayU S.A. z siedzibą w Poznaniu, 60-166
              Poznań, przy ul. Grunwaldzkiej 186, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd
              Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu, Wydział VIII Gospodarczy Krajowego Rejestru
              Sądowego pod numerem 0000274399, NIP: 779-23-08-495, zwana dalej &#8220;Operatorem
              płatności&#8221;.
            </li>
            <li>RODO- Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
              sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
              przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne Rozporządzenie o ochronie
              danych).
            </li>
          </ol>
          <p className="text-center m-10"><strong>§2</strong></p>
          <ol>
            <li>Przed rozpoczęciem korzystania z Serwisu należy zapoznać się z treścią Regulaminu oraz Polityką
              Prywatności i Cookies oraz zaakceptować ich warunki.
            </li>
            <li>Podczas korzystania z Serwisu, obowiązkiem Użytkownika jest podanie prawdziwych, zgodnych ze
              stanem faktycznym danych osobowych oraz ochrona swoich danych osobowych przed dostępem. Należyta
              staranność Użytkownika przy wykonywaniu obowiązków, o których mowa w Regulaminie, ma wpływ na
              zapewnienie bezpieczeństwa Konta tego Użytkownika.
            </li>
            <li>Do korzystania z Serwisu konieczne jest posiadanie przez Użytkownika:</li>
          </ol>
          <ul>
            <li>Dostępu do Internetu;</li>
            <li>Standardowego systemu operacyjnego;</li>
            <li>Standardowej przeglądarki internetowej (np. Internet Explorer, Opera, Firefox, Chrome, Safari,
              Edge itp.) z zaleceniem, by były to najnowsze, stabilne wersje tych przeglądarek;
            </li>
            <li>Adresu e-mail.</li>
          </ul>
          <ol start="4">
            <li>Podstawową funkcjonalnością Serwisu jest usługa elektronicznego generowania dokumentów w
              formacie PDF, tj. wystawiania faktur na podstawie danych wpisanych na stronie Serwisu przez
              Użytkownika.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§3</strong></p>
          <ol>
            <li>Aktualizacje Serwisu udostępniane są Użytkownikowi nieodpłatnie.</li>
            <li>Nowe funkcje udostępniane są przez Operatora Serwisu we wszystkich lub w wybranych przez
              Operatora Serwisu Pakietach
            </li>
            <li>Aktualizacje Serwisu mogą wprowadzać zmiany w jego funkcjonowaniu; o istotnych zmianach Operator
              Serwisu informuje Użytkownika mailowo lub w newsletterze, Obowiązkiem Użytkownika jest upewnienie
              się co do bieżącej poprawności wystawianych dokumentów.
            </li>
            <li>Aktualizacje Serwisu będą wdrażane zgodnie z założonym przez Operatora Serwisu przeznaczeniem
              Serwisu oraz planem jego rozwoju.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§4</strong></p>
          <ol>
            <li>W celu zawarcia Umowy o świadczenie Usług konieczne jest zarejestrowanie się i założenie
              indywidualnego konta Użytkownika w Serwisie.
            </li>
            <li>Użytkownik może założyć konto przy użyciu funkcji rejestracji na stronie internetowej Serwisu
              lub poprzez Aplikację Mobilną.
            </li>
            <li>Moment rejestracji, składającej się z podania przez Użytkownika adresu mailowego, hasła oraz
              przyciśnięcie przycisku „Zarejestruj się” jest uznawany przez Operatora Serwisu jako akceptacja
              przez Użytkownika oferty przedstawionej na stronie https://fakturuj.to/cenniki stanowi moment
              zawarcia przez Strony Umowy o świadczenie usług, której treść reguluje niniejszy Regulamin.
            </li>
            <li>Użytkownik zobowiązany jest podać podczas rejestracji poprawny adres poczty elektronicznej
              (e-mail) umożliwiający przesłanie mu linku aktywacyjnego do konta.
            </li>
            <li>Użytkownik zobowiązany jest zweryfikować poprawność adresu poczty elektronicznej (e-mail),
              podanego podczas procesu rejestracji. W przypadku braku weryfikacji w ciągu 14 dni, licząc od dnia
              rejestracji, Operator Serwisu zastrzega sobie prawo do ograniczenia funkcjonalności konta do czasu
              weryfikacji poprawności podanego adresu e-mail.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§5</strong></p>
          <ol>
            <li>Po założeniu konta Użytkownikowi przysługuje nieodpłatny 45 dniowy dostęp do wybranych funkcji
              Serwisu w celu zapoznania się z funkcjami oferowanymi w poszczególnych Pakietach Abonamentowych
              Serwisu.
            </li>
            <li>Użytkownik wchodząc na stronę <a href="https://fakturuj.to/cennik"
            >https://fakturuj.to/cennik</a> może wybrać Pakiet Abonamentowy  &#8211; „Pakiet Demo na 45 dni” lub
              od razu przejść do płatnego Pakietu Abonamentowego.
            </li>
            <li>W tym celu Użytkownik wypełnia interaktywny formularz „płatność” podając dane: nazwę firmy,
              adres, NIP, adres e-mail, oraz w dalszej części dane dotyczące karty płatniczej.
            </li>
            <li>Jeżeli Użytkownik nie zrezygnuje z bezpłatnego okresu testowego przed upływem 45 dniowego
              terminu, po zakończeniu 45 dniowego bezpłatnego Okresu Testowego, Serwis poinformuje użytkownika o
              zakończeniu okresu testowego i zapyta, który Pakiet wybiera.
            </li>
            <li>Pakiet Abonamentowy aktywny po założeniu konta, zgodny jest z planem wybranym po zakończeniu
              okresu testowego.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§6</strong></p>
          <ol>
            <li>Wyboru Pakietu Abonamentowego dokonuje Użytkownik.</li>
            <li>Użytkownik zaznacza na swoim koncie wybraną opcję Abonamentu. Serwis automatycznie wystawi
              fakturę VAT z tytułu świadczonych Usług. Użytkownik obowiązany jest podać poprawne dane niezbędne
              do wystawienia faktury VAT, w tym NIP. Użytkownik zagraniczny, posiadający NIP europejski,
              obowiązany jest do uzupełnienia swoich danych firmowych o ten numer NIP.
            </li>
            <li>Użytkownik zostanie obciążony kwotą miesięcznego abonamentu, według wybranej Metody Płatności.
              Kwota wskazana na formularzu płatności zgodna jest z kwotą wskazaną w Cenniku, ale może być
              pomniejszona o kwotę rabatu przysługującego ze względu na prowadzone przez Operatora Serwisu lub
              podmioty z nim współpracujące okazjonalnie akcje promocyjne. Metodę Płatności można zmienić na
              witrynie internetowej Serwisu klikając łącze „konto”. Jeżeli płatność nie może być zrealizowana ze
              względu na upływ terminu ważności, brak środków lub z innych przyczyn i jeżeli użytkownik nie
              zmieni Metody Płatności ani nie zrezygnuje z członkostwa, Operator Serwisu będzie miał prawo do
              zawieszenia dostępu do serwisu do momentu wskazania poprawnej Metody Płatności. Użytkownik
              upoważnia Operatora Serwisu do pobierania opłat abonamentowych przy użyciu wybranej Metody
              Płatności także po jej zmianie i ponosi odpowiedzialność za wszelkie niepobrane, należne kwoty.
              Powyższe może skutkować zmianą dat pobierania opłat abonamentowych. W przypadku niektórych Metod
              Płatności Operator Płatności realizujący płatności według danej metody może pobierać od
              użytkownika opłatę z tytułu transakcji zagranicznych lub inne opłaty. W celu uzyskania
              szczegółowych informacji użytkownik powinien skontaktować się z Operatorem Płatności realizującym
              daną Metodę Płatności.
            </li>
            <li>Rabaty przysługujące Użytkownikowi ze względu na prowadzone przez Operatora Serwisu lub podmioty
              z nim współpracujące okazjonalne akcje promocyjne nie podlegają kumulacji.
            </li>
            <li>Rabat przysługujący Użytkownikowi ze względu na prowadzone przez Operatora Serwisu oraz podmioty
              z nim współpracujące akcje promocyjne może zostać wykorzystany przez Użytkownika poprzez
              wprowadzenie kodu rabatowego na formularzu płatności. Niewykorzystany rabat nie przechodzi na
              poczet płatności dokonanych w kolejnych Okresach Rozliczeniowych a Użytkownikowi nie przysługuje
              rekompensata finansowa ani przedłużenie abonamentu z tytułu niewykorzystanego rabatu.
            </li>
            <li>Użytkownik, chcąc uzyskać dostęp do funkcji, która nie występuje w wykupionym lub używanym przez
              Użytkownika Pakiecie Abonamentowym, zobowiązany jest do zmiany pakietu abonamentowego na wyższy,
              zgodnie z Cennikiem.
            </li>
            <li>Usługa zostanie udostępniona w momencie potwierdzenia zaksięgowania środków na koncie Operatora
              Serwisu. Użytkownik otrzyma automatyczne powiadomienie o aktywacji Usługi wraz z linkiem do
              faktury na przypisany do jego konta adres e-mail.
            </li>
            <li>Jeżeli Użytkownik wybrał jako środek płatności kartę płatniczą, wypowiedzenie umowy możliwe jest
              w terminie do 14 (słownie: czternaście) dni przed zakończeniem opłaconego Okresu Rozliczeniowego,
              w przeciwnym przypadku usługa zostanie automatycznie przedłużona na kolejny okres abonamentowy,
              zgodnie z używanym obecnie Pakietem Abonamentowym.
            </li>
            <li>Na 14 dni przed upływem Okresu Rozliczeniowego Serwis przesyła do Użytkownika informację
              dotyczącą możliwości zachowania pełnej funkcjonalności użytkowanego konta. Wiadomość ta (z
              załączoną Fakturą Proformą) stanowi ofertę przedstawioną Użytkownikowi przez Operatora Serwisu,
              dotyczącą możliwości przedłużenia korzystania z dotychczasowej Usługi.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§7</strong></p>
          <ol>
            <li>Użytkownik może w dowolnym momencie zmienić Pakiet Abonamentowy z aktualnie używanego na inny
              plan płatny.
            </li>
            <li>Zmiana Pakietu Abonamentowego z wyższego na niższy wiąże się z ograniczeniem funkcjonalności
              konta zgodnie z Cennikiem.
            </li>
            <li>Ograniczenie funkcjonalności konta skutkować może brakiem dostępu do części danych zapisanych na
              koncie Użytkownika.
            </li>
            <li>Za ewentualne straty danych spowodowane zmianą Pakietu Abonamentowego z wyższego na niższy
              Operator Serwisu nie ponosi odpowiedzialności.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§8</strong></p>
          <ol>
            <li>Faktura VAT wystawiana jest po uiszczeniu zapłaty przez Użytkownika, ze wskazaniem danych
              określonych przez Użytkownika, w formularzu płatności podczas dokonywania opłaty.
            </li>
            <li>W przypadku stwierdzenia niepoprawności wskazanych przez Użytkownika danych nabywcy użytych do
              wystawienia faktury VAT, Użytkownik jest obowiązany przedstawić Operatorowi Serwisu notę
              korygującą.
            </li>
            <li>Za dostarczenie faktury VAT, wysłanej na adres e-mailowy Użytkownika (właściciela konta), uważa
              się przyjęcie przez serwer pocztowy Użytkownika wiadomości zawierającej fakturę VAT.
            </li>
            <li>Ewentualne koszty dodatkowe za usługi na rzecz podmiotόw trzecich, a w szczegόlności związane z
              realizacją płatności, ponosi Użytkownik.
            </li>
            <li>Za dzień zapłaty uznaje się datę uznania kwoty na rachunku bankowym Operatora Serwisu.</li>
            <li>Operator Serwisu zastrzega sobie prawo zmiany Cennika.</li>
          </ol>
          <p className="text-center m-10"><strong>§9</strong></p>
          <ol>
            <li>Umowa zawarta pomiędzy Operatorem Serwisu i Użytkownikiem zawierana jest w momencie założenia
              konta w Serwisie i trwa do momentu jej rozwiązania, również gdy na koncie zmieniany jest Pakiet
              Abonamentowy.
            </li>
            <li>Umowa o świadczenie Usług może zostać rozwiązana przez Użytkownika z zachowaniem formy pisemnej
              w każdym czasie bez podania przyczyny.
            </li>
            <li>Z tytułu rozwiązania Umowy przez Użytkownika nie są pobierane żadne opłaty, jednak opłaty
              Abonamentowe uiszczone przez Użytkownika na rzecz Operatora Serwisu przed usunięciem Konta nie są
              zwracane.
            </li>
            <li>Umowa o świadczenie Usług może zostać rozwiązana w trybie natychmiastowym przez Operatora
              Serwisu w następujących przypadkach:
            </li>
            <li>Użytkownik w istotny sposób naruszył postanowienia niniejszego Regulaminu;</li>
            <li>Użytkownik działał na szkodę Operatora Serwisu, innych Użytkowników Serwisu lub sieci
              Internet;
            </li>
            <li>Użytkownik podał nieprawdziwe dane;</li>
            <li>Użytkownik wykorzystał konto w Serwisie niezgodnie z jego przeznaczeniem oraz zakresem funkcji,
              które przewiduje wybrany i opłacony przez niego Pakiet Abonamentowy;
            </li>
            <li>Użytkownik działał niezgodnie z prawem lub podjął działania zagrażające bezpieczeństwu danych,
              znajdujących się w Serwisie.
            </li>
            <li>W sytuacjach opisanych w §8 pkt. 4 Użytkownik nie może dochodzić żadnych roszczeń od Operatora
              Serwisu ani dochodzić o zwrot dokonanej opłaty. W szczególności wyłączone jest prawo dochodzenia
              przez Użytkownika od Operatora Serwisu odszkodowania na zasadach ogólnych , a Operator Serwisu ma
              prawo do całkowitego zablokowania dostępu do konta Użytkownika nawet za miesiące księgowe, za
              które Użytkownik dokonał opłat.
            </li>
            <li>Usunięcie konta w Serwisie może być dokonane jedynie przez Użytkownika, będącego właścicielem
              konta. W tym celu należy skorzystać z funkcji Serwisu „Usuń konto” lub skontaktować się z <a
                href="mailto:pomoc@fakturuj.to"
              >pomoc@fakturuj.to</a> . Po zastosowaniu tej funkcji, właściciel konta zobowiązany jest
              potwierdzić decyzję o usunięciu konta. Z chwilą usunięcia Konta przez Użytkownika wszystkie dane
              Użytkownika zostają nieodwracalnie usunięte.
            </li>
            <li>Za ewentualne straty danych spowodowane usunięciem konta przez Użytkownika Operator Serwisu nie
              ponosi odpowiedzialności.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§10</strong></p>
          <ol>
            <li>Aplikacja Mobilna FAKTURUJ.TO dostępna jest do pobrania w sklepach internetowych Google Play lub
              Apple App Store.
            </li>
            <li>Korzystanie z Aplikacji Mobilnej wymaga urządzenia mobilnego z zainstalowanym systemem
              operacyjnym Android lub iOS oraz dostępu do sieci Internet. Minimalna wersja oprogramowania
              dostępna jest w opisie Aplikacji na stronie sklepu.
            </li>
            <li>Pobranie Aplikacji ze sklepów wskazanych w § 10 pkt.1 niniejszego Regulaminu jest bezpłatne.
            </li>
            <li>Usługi świadczone za pośrednictwem Aplikacji Mobilnej polegają w szczególności na:</li>
          </ol>
          <ul>
            <li>dostępie do listy wystawionych dokumentów;</li>
            <li>dostępie do podstawowych statystyk konta;</li>
            <li>możliwości wystawienia dokumentów przychodowych;</li>
            <li>możliwości umieszczenia dokumentów kosztowych.</li>
          </ul>
          <ol start="5">
            <li>Zakres dostępu do funkcji oferowanych przez Aplikację Mobilną może się rόżnić z zakresem
              dostępnych funkcji Serwisu zgodnie z obowiązującym Cennikiem w aktualnym Pakiecie Abonamentowym
              Użytkownika. W celu dostępu do pełnej funkcjonalności Serwisu, odpowiadającej wykupionemu przez
              Użytkownika Pakiecie Abonamentowemu, Użytkownik musi zalogować się do swojego konta na Serwisie z
              przeglądarki internetowej.
            </li>
            <li>Użytkownik w ramach Aplikacji Mobilnej może zarejestrować się, zakładając nowe konto, lub
              korzystać z konta, które uprzednio zostało założone w Serwisie.
            </li>
            <li>Konto Użytkownika dostępne w Serwisie w wersji przeglądarkowej oraz w Aplikacji Mobilnej jest
              tym samym kontem, a logowanie do niego poprzez Aplikację Mobilną odbywa się przy wykorzystaniu
              tego samego loginu i hasła.
            </li>
            <li>Użytkownik może zrezygnować z używania Aplikacji Mobilnej poprzez jej odinstalowanie, co nie
              wiąże się z usunięciem danych konta w wersji standardowej Serwisu.
            </li>
            <li>Koszt transmisji danych wymaganych do pobrania, uruchomienia a także korzystania z Aplikacji
              Mobilnej Użytkownik pokrywa we własnym zakresie, zgodnie z umową zawartą przez niego z
              zewnętrznymi dostawcami usług internetowych.
            </li>
            <li>Aplikacja Mobilna jest przedmiotem autorskich praw majątkowych Operatora Serwisu. Wszelkie
              elementy graficzne, logotypy, znaki towarowe oraz układ interfejsu w niej prezentowane podlegają
              ochronie prawnej.
            </li>
            <li>Użytkownicy są zobowiązani do korzystania z Aplikacji Mobilnej w sposób zgodny z niniejszym
              Regulaminem, regulaminami sklepów, z których została pobrana oraz obowiązującym prawem.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§11</strong></p>
          <ol>
            <li>Serwis dostępny jest dla Użytkownikόw przez całą dobę. Operator Serwisu zastrzega sobie prawo do
              krótkich przerw wynikających z okresowej konserwacji Serwisu lub spowodowanych zdarzeniami
              losowymi.
            </li>
            <li>Operator Serwisu zobowiązuje się do niezwłocznej naprawy wszelkich usterek lub awarii
              uniemożliwiających lub utrudniających dostęp do Serwisu. W przypadku nieusunięcia zgłoszonych
              usterek lub awarii w terminie dłuższym niż 7 dni, Użytkownik może rozwiązać Umowę ze skutkiem
              natychmiastowym.
            </li>
            <li>Użytkownik, korzystając z Usługi, wyraża zgodę na otrzymywanie faktur VAT od Operatora Serwisu
              drogą elektroniczną, zgodnie z art. 106n ust. 1 Ustawy z dnia 11 marca 2004 r. (Dz.U. 2017, poz.
              1221 ze zm.) o podatku od towarów i usług.
            </li>
            <li>Operator Serwisu umożliwia Użytkownikowi wysyłanie faktur w wersji elektronicznej. Użytkownicy
              oświadczają, iż otrzymali od swoich kontrahentów stosowną zgodę na otrzymanie faktury drogą
              elektroniczną w rozumieniu art. 106n ust. 1 ww. Ustawy z dnia 11 marca 2004 r. o podatku od
              towarów i usług.
            </li>
            <li>Użytkownik zobowiązuje się do korzystania z Usług w sposób nienaruszający praw osób trzecich
              (dóbr osobistych, praw autorskich), dobrych obyczajów i przepisów prawa.
            </li>
            <li>Użytkownik jest właścicielem całej treści wprowadzonej za pomocą Usługi do Serwisu lub Witryny,
              za którą Operator Serwisu nie ponosi żadnej odpowiedzialności. Wszelką odpowiedzialność,
              wynikającą z przepisów prawa, dotyczącą publikacji treści przez Użytkownika Serwisu, ponosi
              Użytkownik.
            </li>
            <li>Użytkownik jest odpowiedzialny za wprowadzane do Serwisu dane, treści, materiały oraz czynności
              wykonane w związku z korzystaniem z Serwisu , a także za osoby trzecie, które z jego powodu w
              jakikolwiek sposób uzyskały dostęp do Serwisu o danych w nim zawartych.
            </li>
            <li>W przypadku otrzymania zgłoszenia o bezprawnym charakterze danych zamieszczonych przez
              Użytkownika w Serwisie, Operator Serwisu ma prawo, zgodnie z art. 14 Ustawy z dnia 18 lipca 2002
              r. o świadczeniu o świadczeniu usług drogą elektroniczną, do uniemożliwienia dostępu do danych
              Użytkownika. W takim przypadku Użytkownik zostanie powiadomiony o wprowadzeniu blokady konta.
            </li>
            <li>Operator Serwisu zastrzega sobie prawo do usunięcia wybranych treści wprowadzonych przez
              Użytkownika na Witrynę lub jej podstrony.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§12</strong></p>
          <ol>
            <li>Operator Serwisu nie ponosi odpowiedzialności za szkody wynikające z:</li>
          </ol>
          <ul>
            <li>przerw w dostawie Usługi lub niedostępności Serwisu niezależnych od Operatora Serwisu lub
              będących następstwem zdarzeń, których Operator Serwisu nie mógł przewidzieć;
            </li>
            <li>niepoprawnego funkcjonowania Serwisu niebędącego winą Operatora Serwisu, na przykład, błędów
              sieci telekomunikacyjnej;
            </li>
            <li>dostępu do konta Użytkownika przez osoby nieautoryzowane;</li>
            <li>nieznajomości lub nieprzestrzegania przez Użytkownika niniejszego Regulaminu;</li>
            <li>niebezpieczeństw związanych z użytkowaniem sieci: ataków hakerskich, zainfekowania systemu przez
              wirusy lub zdarzeń podobnych.<br/>
              2. Całkowita odpowiedzialność Operatora Serwisu względem Użytkownika w każdym przypadku
              ograniczona jest do wysokości sumy opłat dokonanych w ciągu trzech ostatnich lat, jakie Użytkownik
              wniósł na rzecz Operatora Serwisu z tytułu świadczenia usług.
            </li>
          </ul>
          <p className="text-center m-10"><strong>§13</strong></p>
          <ol>
            <li>Reklamacje dotyczące funkcjonowania Serwisu powinny być zgłaszane pocztą elektroniczną na
              adres: <a href="mailto:pomoc@fakturuj.to">pomoc@fakturuj.to</a> lub listownie na adres: Fakturuj
              sp. z o.o. ul. Jana z Kolna, 80-864 Gdańsk
            </li>
            <li>W treści zgłoszenia należy podać: imię i nazwisko zgłaszającego, adres poczty elektronicznej
              Użytkownika, opis zgłaszanych zastrzeżeń oraz swoje żądania.
            </li>
            <li>Termin rozpatrzenia reklamacji wynosi 14 (słownie: czternaście) dni od dnia otrzymania
              reklamacji przez Operatora Serwisu.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§14</strong></p>
          <ol>
            <li>Operator Serwisu jako administrator danych osobowych zobowiązuje się podjąć wszelkie konieczne
              środki techniczne i organizacyjne odpowiednie do stopnia zagrożenia, w celu zapewnienia
              bezpieczeństwa wszystkich danych i treści przekazywanych przez Użytkownika w związku z
              korzystaniem przez niego ze Sklepu Internetowego.
            </li>
            <li>Dane osobowe Użytkowników zbierane przez administratora za pośrednictwem Serwisu zbierane są w
              celu realizacji Umowy.
            </li>
            <li>Dane osobowe Klientów przetwarzane są zgodnie z postanowieniami RODO oraz Ustawy z dnia 10 maja
              2018 r. o ochronie danych osobowych (Dz.U. z 2018 r. poz. 1000) [zwanej dalej: „Ustawą”].
              Użytkownik, który dokonał rejestracji w ramach Serwisu ma prawo wglądu do swoich danych, do ich
              korekty oraz żądania zaprzestania ich wykorzystywania. Dane można przeglądać oraz zmieniać po
              zalogowaniu do Serwisu, w ramach indywidualnego Konta danego Użytkownika lub poprzez kontakt z
              Operatorem Serwisu pod adresem e-mail: <a href="mailto:pomoc@fakturuj.to">pomoc@fakturuj.to</a>
            </li>
            <li>Podmiot odpowiedzialny za przetwarzanie danych osobowych:</li>
          </ol>
          <p>
            Fakturuj sp. z o. o. z siedzibą w Gdańsku<br/>
            ul.Jana z Kolna 11<br/>
            80-864 Gdańsk
          </p>
          <ol start="5">
            <li>Szczegółowe zasady dotyczące przetwarzania danych osobowych Użytkowników zawiera POLITYKA
              PRYWATNOŚCI.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§15</strong></p>
          <ol>
            <li>Wszystkie zdjęcia, grafiki, logotypy, publikacje i dokumenty dostępne w Serwisie są własnością
              Operatora Serwisu bądź innych osób posiadających do nich prawa autorskie i są chronione ustawą z
              dnia 04 lutego 1994 roku o prawie autorskim i prawach pokrewnych (Dz.U. z 2018 r., poz. 1191 ze
              zm.).
            </li>
            <li>Zakazane jest bezprawne kopiowanie, zwielokrotnianie bądź rozpowszechnianie wszelkich treści
              zawartych na stronie Sklepu Internetowego.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§16</strong></p>
          <ol>
            <li>Ewentualne spory, mogące wyniknąć w trakcie realizacji Umowy, Operator Serwisu będzie starał się
              rozstrzygać polubownie, a w przypadku braku rozwiązania polubownego, rozstrzyganie ewentualnych
              sporów pomiędzy Operatorem Serwisu a Użytkownikiem, zostaje poddane sądom powszechnym właściwym
              zgodnie z przepisami Kodeksu postępowania cywilnego.
            </li>
            <li>Rozstrzyganie ewentualnych sporów pomiędzy Operatorem Serwisu a Użytkownikiem, który nie jest
              konsumentem w rozumieniu Kodeksu Cywilnego, zostaje poddane sądowi właściwemu ze względu na
              siedzibę Operatora Serwisu.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§17</strong></p>
          <ol>
            <li>Regulamin stanowi integralną część zawartej, pomiędzy Operatorem Serwisu a Użytkownikiem,
              Umowy.
            </li>
            <li>Regulamin wchodzi w życie z dniem publikacji w Witrynie Operatora Serwisu.</li>
            <li>Operator zastrzega sobie możliwość zmian Regulaminu. Do umów zawartych przed zmianą Regulaminu
              stosuje się wersję Regulaminu obowiązującą w dacie zawarcia umowy. O każdej zmianie Regulaminu
              Operator Serwisu poinformuje Użytkowników.
            </li>
            <li>W sprawach nieuregulowanych w Regulaminie stosuje się odpowiednio przepisy ustawy z dnia
              23.04.1964 r. &#8211; Kodeks cywilny lub innych ustaw mających zastosowanie do działalności i
              funkcjonowania Serwisu zgodnie z prawem polskim.
            </li>
          </ol>
          <p>Załączniki:</p>
          <p><u>Załącznik nr 1</u></p>
          <p>Umowa powierzenia przetwarzania danych</p>
          <p className="text-center m-10"><strong>§1</strong></p>
          <ol>
            <li>Użytkownik, będący administratorem wprowadzanych do Serwisu danych ze zbioru “Dane powierzane”,
              powierza Operatorowi Serwisu, w trybie art. 28 Rozporządzenia jako przedmiot przetwarzania dane
              osobowe w zakresie zgodnym i wybranym przez Użytkownika do tego zbioru, zgodnie z zakresem
              wyszczególnionym w ust. 2 oraz na zasadach i w celu określonym w ust.3.
            </li>
            <li>Zakres danych ze zbioru „Dane powierzane” obejmuje dane kontrahentów administratora, będące
              danymi zwykłymi, a w szczegόlności: Imię, Nazwisko, Numer identyfikacji podatkowej lub PESEL oraz
              dane pracowników administratora, będące danymi zwykłymi, a w szczegόlności: Adres e-mail, Imię,
              Nazwisko, Funkcja w serwisie, Przypisany Dział Firmy.
            </li>
            <li>Powierzenie przetwarzania danych osobowych następuje wyłącznie w celu i w zakresie realizacji
              Usługi.
            </li>
            <li>Przetwarzanie powierzonych danych osobowych odbywa się po stronie Operatora Serwisu przy
              wykorzystaniu systemów informatycznych.
            </li>
            <li>Operator Serwisu przetwarza powierzone mu do przetwarzania dane w okresie świadczenia Usługi,
              tj. tylko przez okres, w którym będzie do tego podstawa prawna, a wiec do momentu w którym :
            </li>
          </ol>
          <p>&#8211; przestanie na administratorze ciążyć prawny obowiązek zobowiązujący do przetwarzania danych
            osobowych Użytkownika;</p>
          <p>&#8211; ustanie możliwość dochodzenia roszczeń związanych z zawartą przez strony umową;</p>
          <p>&#8211; zgoda zostanie cofnięta, jeśli zgoda ta była podstawą przetwarzania;</p>
          <p>&#8211; w zależności od tego, co ma zastosowanie w danym przypadku i co nastąpi później.</p>
          <p className="text-center m-10"><strong>§2</strong></p>
          <ol>
            <li>Operator Serwisu zobowiązuje się, w ramach swojej organizacji, przetwarzać powierzone dane ze
              szczególną starannością, zgodnie z RODO oraz innymi przepisami prawa powszechnie obowiązującego,
              które chronią prawa osób, których dane dotyczą, w szczególności poprzez zastosowanie odpowiednich
              środków technicznych i organizacyjnych zapewniających ochronę przetwarzania danych osobowych
              adekwatną do zagrożeń związanych z przetwarzaniem danych osobowych, o których mowa w art. 32 RODO.
            </li>
            <li>Operator Serwisu zobowiązuje się do nadania upoważnień do przetwarzania danych osobowych
              wszystkim osobom, które będą przetwarzały powierzone dane w celu realizacji niniejszej Umowy oraz
              zobligować je do zachowania w tajemnicy (o której mowa w art. 28 ust 3 pkt b Rozporządzenia)
              przetwarzanych danych zarówno w trakcie zatrudniania ich przez Operatora Serwisu, jak i po ustaniu
              zatrudnienia.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§3</strong></p>
          <ol>
            <li>Biorąc pod uwagę charakter przetwarzania, w miarę możliwości Operator Serwisu będzie pomagał
              Użytkownikowi administrującemu danymi w niezbędnym zakresie, poprzez odpowiednie środki
              techniczne i organizacyjne wywiązać się z obowiązku odpowiadania na żądania osoby, której
              dane dotyczą, w zakresie wykonywania jej praw określonych w rozdziale III Rozporządzenia oraz,
              uwzględniając charakter przetwarzania oraz dostępne mu informacje, będzie pomagał
              administratorowi wywiązać się z obowiązków określonych w art. 32–36 Rozporządzenia.
            </li>
            <li>Operator Serwisu po stwierdzeniu naruszenia ochrony danych osobowych bez zbędnej zwłoki zgłasza
              je Administratorowi.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§4</strong></p>
          <ol>
            <li>Zgodnie z art. 28 ust. 3 pkt h Rozporządzenia Użytkownik będący administratorem danych
              powierzanych Operatorowi Serwisu ma prawo skontrolowania, czy środki zastosowane przez Operatora
              Serwisu przy przetwarzaniu i zabezpieczeniu powierzonych danych osobowych spełniają postanowienia
              umowy. Prawo do przedmiotowej kontroli może być realizowane w godzinach pracy Operatora serwisu
              tj. poniedziałek &#8211; piątek w godzinach 9 &#8211; 17 z wykluczeniem dni ustawowo wolnych od
              pracy i z minimum siedmiodniowym uprzedzeniem o zamiarze dokonania kontroli. W trakcie kontroli
              Operator Serwisu udostępni Użytkownikowi administrującemu danymi powierzanymi wszelkie informacje
              niezbędne do wykazania spełnienia obowiązków określonych w art. 28 Rozporządzenia.
            </li>
            <li>W razie stwierdzenia uchybień w trakcie kontroli, Operator Serwisu zobowiązuje się do ich
              usunięcia w terminie wskazanym przez Administratora danych, nie dłuższym niż 14 (słownie:
              czternaście) dni.
            </li>
          </ol>
          <p className="text-center m-10"><strong>§5</strong></p>
          <p>Administrator danych może rozwiązać niniejszą Umowę ze skutkiem natychmiastowym, gdy Operator
            Serwisu, pomimo zobowiązania go do usunięcia uchybień stwierdzonych podczas kontroli, nie usunie ich
            w wyznaczonym terminie lub przetwarza dane osobowe w sposób niezgodny z niniejszą umową.</p>
          <p className="text-center m-10"><strong>§6</strong></p>
          <ol>
            <li>Operator Serwisu powierza dane osobowe objęte niniejszą Umową do dalszego przetwarzania
              zewnętrznym podmiotom w obrębie Europejskiego Obszaru Gospodarczego oraz, zgodnie z artykułem 45
              pkt. 1 Rozporządzenia, podmiotom w państwach trzecich lub organizacji międzynarodowej tylko w
              przypadku, gdy Komisja stwierdzi, że to państwo trzecie, terytorium lub określony sektor lub
              określone sektory w tym państwie trzecim lub dana organizacja międzynarodowa zapewniają
              odpowiedni stopień ochrony. Dane powierzane są jedynie w celu i zakresie wykonania niniejszej
              Umowy, na co Operator Serwisu otrzymuje zgodę Administratora.
            </li>
            <li>Operator Serwisu posiada podpisaną Umowę powierzenia przetwarzania danych osobowych z podmiotami
              wskazanymi w ust. 1.
            </li>
            <li>Przekazanie powierzonych danych do państwa trzeciego lub organizacji międzynarodowej, w
              przypadku gdy Komisja nie stwierdzi, że to państwo trzecie, terytorium lub określony sektor lub
              określone sektory w tym państwie trzecim lub dana organizacja międzynarodowa zapewniają
              odpowiedni stopień ochrony, może nastąpić jedynie na udokumentowane polecenie Administratora
              danych, chyba że obowiązek przekazania danych nakłada na Operatora Serwisu prawo polskie lub
              unijne. W takim przypadku przed rozpoczęciem przetwarzania Operator Serwisu informuje
              Administratora danych o tym obowiązku prawnym, o ile prawo to nie zabrania udzielania takiej
              informacji z uwagi na ważny interes publiczny.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </SubpageLayout>
);

export default TermsPage;
